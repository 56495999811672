.btn-selected {
  border-bottom: 2px solid #050a1d;
  color: #050a1d;
  &:hover {
    color: #050a1d;
    border-bottom: 2px solid #050a1d;
  }
}

.btn-not-selected {
  color: #a7b1be;
  &:hover {
    color: #a7b1be;
  }
}

.swiper-container {
  border-bottom: 2px solid #e5e7f0;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    opacity: 1;
    border: white solid 1px;
    background-color: transparent;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--theme-deafult);
  }
}

.swiper {
  padding-inline: 2.8125rem;
  margin-bottom: -2px;

  .swiper-button-prev,
  .swiper-button-next {
    top: 25px;
    background-color: #f8f8f8;
    width: 2.1875rem;
    height: 50%;
    margin-top: 0;
    align-items: flex-start;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-prev:after {
    content: url('assets/images/icons/arrow-left-swipper.svg');
  }

  .swiper-button-next:after {
    content: url('assets/images/icons/arrow-right-swipper.svg');
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: var(--theme-deafult);
    font-size: 24px !important;
  }

  .swiper-wrapper {
    min-height: 45px;

    .swiper-slide {
      display: flex !important;
      flex-direction: column;
      width: fit-content !important;

      .btn {
        display: flex;
        padding: 19px 24px;
        line-height: 21px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0;
        font-weight: 500;
        background-color: transparent;

        &.remove-inline-padding {
          padding: 19px 12px !important;
        }

        .subitem-tabs-info {
          margin-bottom: 0;
          margin-top: 5px;
          font-size: 14px;
        }

        &:first-child:active {
          border-color: transparent;
        }
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .swiper {
    padding-inline: 0;
  }

  .subitem-tabs {
    margin-left: 45px !important;
  }
}

@media screen and (max-width: 567px) {
  .total-potency {
    flex-direction: column;
  }

  .swiper-slide {
    padding-inline: 10px;
    display: flex;
  }
}
