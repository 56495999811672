@import '../../../../theme.scss';

.ngx-datatable {
  /* border: 2px solid #f3f3f3; */
  /*
  border: 2px solid #ecf3fa */
  border-radius: 8px;
}

.datatable-header-cell-label {
  color: #050a1d;
  font-size: 1rem;
  line-height: 19px;
  /* margin: 0 8px; */
  font-weight: 500;
  white-space: normal;
  font-family: var(--font-default);
  font-size: 17px !important;
}

.datatable-header-cell {
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  /* border-right: 2px solid #dadada; */
}

.datatable-header-cell-wrapper {
  display: flex;
  align-items: center;
}

.datatable-header-cell-template-wrap {
  display: flex;
  align-items: center;
}

.datatable-icon-sort-unset {
  color: #050a1d;

  &::before {
    font-size: 18px;
  }
}

.datatable-row-center {
  justify-content: space-between;

  &.datatable-row-group {
    span {
      display: flex;
      font-size: 14px;
      color: black;
      margin: 0;
      font-weight: normal;
      justify-content: center;
    }

    :first-child {
      .datatable-body-cell-label {
        span {
          color: black;
        }
      }
    }
  }
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  display: none !important;
}

.ngx-datatable.bootstrap {
  .datatable-footer {
    /* display: none; */
    background-color: #fff;
    color: $gray-600;

    .page-count {
      display: none;
      span {
        display: none;
      }
    }

    /* border-top: 2px solid #f3f3f3; */
  }

  .datatable-body {
    .datatable-body-row {
      &.datatable-row-even {
        background-color: $white;
        color: black;
      }

      &.active {
        background-color: $white;
        color: black;
      }

      .datatable-body-cell {
        background-color: $white;
        color: black;
        display: flex;
        align-items: center;
        /* border-right: 2px solid #f3f3f3; */
        padding: 0.3rem !important;
        .datatable-body-cell-label {
          display: flex;
          span {
            font-size: 17px !important;
          }
        }
      }

      border-top: 0.01px solid #e9e9e9;
    }
  }
}

.mat-icon-button {
  width: 30px !important;
  height: 30px !important;
}

// align "ACTION" column to the end of it's space, just add the class .align-last-col-to-end at <app-table selector
.align-last-col-to-end {
  .datatable-row-center.datatable-row-group {
    .datatable-body-cell:last-child {
      justify-content: flex-end;
    }
  }

  datatable-header-cell {
    &:nth-last-child(1) {
      .datatable-header-cell-template-wrap {
        justify-content: flex-end;
        padding-right: 12px;
      }
    }
  }
}

.datatable__filters {
  font-size: 0.875rem;

  mat-form-field {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background-color: black;
    }
  }
}

.datatable__filters-action-buttons {
  padding-top: 8px;
}
