/**=====================
     14. Chat CSS Start
==========================**/
.chat-box {
  .chat-menu {
    min-width: 331px;
  }
  .people-achive-list {
    .search {
      position: relative;
      .form-control {
        padding-left: 52px;
        background-color: transparent;
        border: 2px solid #e4e8f1;
        border-radius: 6px;
        font-weight: 500;
        line-height: 19px;
        font-size: 16px;
        height: 48px;
        color: $theme-body-font-color;
        &::placeholder {
          font-weight: 500;
          font-size: 16px;
          color: $theme-body-font-color;
        }
      }
      i {
        position: absolute;
        left: 26px;
        top: 18px;
        font-size: 14px;
        color: $theme-body-font-color;
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $theme-font-color;
      font-weight: 500;
    }
  }
  .status {
    color: $theme-body-font-color;
    font-size: 14px;
    margin-top: 5px;

    .chat-status {
      font-weight: 600;
      color: $theme-body-font-color;
    }
    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 167px;
      line-height: 24px;
      color: #647387;
      margin-bottom: 0 !important;
    }
  }
  .chat-right-aside {
    .chat {
      .chat-header {
        padding: 0 24px 15px 24px;
        img {
          float: left;
          width: 56px;
          height: 56px;
          box-shadow: 1px 1px 4px 1px $light-gray;
          margin-left: -20px;

          &:first-of-type {
            margin-left: 0 !important;
          }
        }
        .chat-menu-icons {
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 38px;
            width: 38px;
            height: 38px;
            margin-right: 24px;
            cursor: pointer;
            background-color: #dfe5ec;
            &:last-of-type {
              margin-right: 0;
            }
            a {
              display: flex;
              .default-icon {
                display: flex;
                color: $theme-body-sub-title-color;
                svg {
                  width: 20px;
                  height: 20px;
                  margin-right: 0px;
                  position: initial;
                }
              }
              .alert {
                color: white;
                padding: 0;
              }
            }

            &.toogle-bar {
              display: none;
            }
          }
          .alert-circle {
            background-color: #f5cb4c;
          }
        }
      }
      .chat-msg-box {
        padding: 15px 24px 0px 24px;
        overflow-y: auto;
        height: 560px;
        margin-bottom: 100px;
        .chat-user-img {
          margin-top: -35px;
        }
        .message-data-time {
          font-size: 12px;
          color: $semi-dark;
        }
        .message {
          line-height: 1.9;
          font-size: 14px;
          margin-bottom: 10px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border: 1px solid $light-color;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          background-color: $light;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 24px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        width: calc(100% - 15px);
        background-color: $white;
        bottom: 0;
        .smiley-box {
          cursor: pointer;
          padding: 10px;
          height: 48px;
          display: block;
          border: 2px solid #e4e8f1;
          border-left-width: 0px;
          border-radius: 0 6px 6px 0 !important;
          margin-right: 12px;
        }
        .text-box {
          position: relative;
          .input-txt-bx {
            height: 48px;
            border: 2px solid #e4e8f1 !important;
            border-right-width: 0px !important;
            border-radius: 6px 0 0 6px;
            padding-left: 18px;

            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #647387;

            &::placeholder {
              filter: opacity(0.8);
            }
          }
          i {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn {
            font-size: 16px;
            font-weight: 500;
            padding: 0.74rem 1.75rem;
            border-radius: 6px;
            height: 48px;
          }
        }
      }
    }
  }
  .chat-menu {
    border-left: 1px solid $light-color;
    .tab-pane {
      padding: 0 15px;
    }
    ul {
      li {
        .about {
          .status {
            i {
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content {
        h5 {
          margin: 25px 0;
        }
        hr {
          margin: 25px 0;
        }
        p {
          font-size: 16px;
        }
      }
      .border-tab.nav-tabs .nav-item .nav-link.active,
      .border-tab.nav-tabs .nav-item .nav-link.show,
      .border-tab.nav-tabs .nav-item .nav-link:focus {
        border-bottom: 0;
      }
      .image {
        position: relative;
        .icon-wrapper {
          position: absolute;
          bottom: 0;
          left: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;
          font-size: 14px;
          box-shadow: 1px 1px 3px 1px $light-color;
        }
        .avatar img {
          border-radius: 50%;
          border: 5px solid $light-color;
        }
      }
      .border-right {
        border-right: 1px solid $light-color;
      }
      .follow {
        margin-top: 0;
        .follow-num {
          font-size: 22px;
          color: $black;
        }
        span {
          color: $theme-font-color;
          font-size: 14px;
        }
      }
      .social-media a {
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact {
        p {
          font-size: 14px;
          color: $semi-dark;
        }
      }
    }
    .nav {
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width: 33.33%;
      a {
        padding: 15px !important;
        color: $semi-dark !important;
        font-size: 14px;
        font-weight: 500;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .material-border {
        border-width: 1px;
        border-color: var(--theme-deafult);
      }
      .nav-link.active {
        color: $black !important;
      }
    }
  }
  .chat-history {
    .call-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
    }
    .total-time h2 {
      font-size: 50px;
      color: $light-semi-gray;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .receiver-img {
      margin-top: 55px;
      img {
        border-radius: 5px;
      }
    }
    .call-icons {
      margin-bottom: 35px;
      ul {
        li {
          width: 60px;
          height: 60px;
          border: 1px solid $light-color;
          border-radius: 50%;
          padding: 12px;
          + li {
            margin-left: 10px;
          }
          a {
            color: #999;
            font-size: 25px;
          }
        }
      }
    }
  }
}
.chat-left-aside {
  min-width: 331px;
  & > .media {
    margin-bottom: 15px;
  }
  .people-achive-list {
    height: 625px;
  }
  ul li {
    position: relative;
  }
}
.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid $white;
}
.away {
  background-color: $warning-color;
}
.online {
  background-color: $success-color;
}
.offline {
  background-color: $danger-color;
}
.chat-container {
  .aside-chat-left {
    width: 320px;
  }
  .chat-right-aside {
    width: 320px;
  }
}
.call-chat-sidebar {
  max-width: 320px;
}
.call-chat-sidebar,
.chat-body {
  .card {
    .card-body {
      padding: 15px;
    }
  }
}

svg path.customStoke {
  fill: none;
}

/**=====================
    14.  Chat CSS Ends
==========================**/
