/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
}

.breadcrumb-colored {
  li {
    a {
      color: $breadcrumb-ancher-color;
    }
    &.active {
      color: $breadcrumb-ancher-color;
      opacity: 0.7;
    }
  }
  .breadcrumb-item {
    &:before {
      color: $breadcrumb-ancher-color;
    }
  }
  .breadcrumb-item.txt-dark {
    &:before {
      color: $breadcrumb-ancher-dark-color;
    }
  }
}
.breadcrumb-item {
  &.active {
    text-transform: capitalize;
  }
}
/**=====================
    57. breadcrumb CSS ends
==========================**/
