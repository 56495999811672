/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://angular.pixelstrap.com/cuba
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
*/

/* ---------------------
	*** Utils ***
-----------------------*/
@import 'bootstrap/scss/bootstrap';

@import 'utils/variables';

@import './components/ngx-datatable';

:root {
  --theme-deafult: #e05f57;
  --theme-secondary: rgba(224, 95, 87, 0.1);
  --font-default: 'Roboto';
  --font-content: 'Roboto';
  --hover-icon-color: invert(49%) sepia(38%) saturate(1008%) hue-rotate(314deg)
    brightness(93%) contrast(89%);
  --bs-btn-hover-bg: #e05f57;
  --bs-btn-hover-color: #ffffff;
}

.eye-password {
  position: relative;
  top: 0.3rem;
  cursor: pointer;
  color: var(--theme-deafult);
}

.mat-tooltip {
  font-family: var(--font-default), sans-serif;
  font-weight: 500;
  font-size: 14px;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgba(100, 115, 135, 0.2) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(100, 115, 135, 0.7);
  border-radius: 20px;
}
/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import 'vendors/whether-icon';
@import 'vendors/flag-icon';
@import 'vendors/icofont';
@import 'vendors/themify';

/* ---------------------
	*** Base ***
-----------------------*/

@import 'base/reset';
@import 'base/typography';

/* ---------------------
	*** Components ***
-----------------------*/

@import 'components/according.scss';
@import 'components/alert.scss';
@import 'components/avatars.scss';
@import 'components/badge.scss';
@import 'components/bookmark.scss';
@import 'components/breadcrumb.scss';
@import 'components/buttons.scss';
@import 'components/card.scss';
@import 'components/dropdown.scss';
@import 'components/form-input.scss';
@import 'components/form-wizard.scss';
@import 'components/forms.scss';
@import 'components/icons.scss';
@import 'components/list.scss';
@import 'components/loader.scss';
@import 'components/popover.scss';
@import 'components/print.scss';
@import 'components/radio.scss';
@import 'components/ribbon.scss';
@import 'components/switch.scss';
@import 'components/tab.scss';
@import 'components/table.scss';
@import 'components/touchspin.scss';

@import 'components/angular-plugin.scss';
@import 'components/calendar.scss';
@import 'components/datatable.scss';
@import 'components/datepicker.scss';
@import 'components/swipper.scss';
@import 'components/typeahead-search';
@import 'components/rating';

/* ---------------------
	*** Pages ***
-----------------------*/

@import 'pages/blog.scss';
@import 'pages/bookmark-app.scss';
@import 'pages/cart.scss';
@import 'pages/chart.scss';
@import 'pages/chat.scss';
@import 'pages/checkout.scss';
@import 'pages/comingsoon.scss';
@import 'pages/contacts.scss';
@import 'pages/dashboard_2.scss';
@import 'pages/dashboard_default.scss';
@import 'pages/ecommerce.scss';
@import 'pages/email-application.scss';
@import 'pages/errorpage.scss';
@import 'pages/faq.scss';
@import 'pages/file.scss';
@import 'pages/gallery.scss';
@import 'pages/job-search.scss';
@import 'pages/knowledgebase.scss';
@import 'pages/language.scss';
@import 'pages/learning.scss';
@import 'pages/login.scss';
@import 'pages/megaoption.scss';
@import 'pages/order-history.scss';
@import 'pages/page.scss';
@import 'pages/pricing.scss';
@import 'pages/progress.scss';
@import 'pages/projectlist.scss';
@import 'pages/social-app.scss';
@import 'pages/task.scss';
@import 'pages/user-profile.scss';
@import 'pages/wishlist.scss';

/* ---------------------
	*** Themes ***
-----------------------*/

@import 'themes/dark.scss';
@import 'themes/dark-sidebar.scss';
@import 'themes/theme-customizer.scss';

/* ---------------------
	*** Layout ***
-----------------------*/

@import 'layout/footer.scss';
@import 'layout/grid.scss';
@import 'layout/header.scss';
@import 'layout/navs.scss';
@import 'layout/search.scss';
@import 'layout/select2.scss';
@import 'layout/sidebar.scss';
@import 'layout/rtl.scss';
@import 'layout/box-layout.scss';
