@import './assets/scss/app';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '@swimlane/ngx-datatable/themes/material.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:root {
  --mat-select-trigger-text-font: 'Roboto', sans-serif;
  --mat-select-panel-background-color: white;
  --bs-heading-color: rgb(100, 115, 135);
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.main-modal .mat-dialog-container {
  border-radius: 18px !important;
}

.transparent .mat-dialog-container {
  background: transparent !important;
  box-shadow: none !important;
}

.danger {
  color: #dc3545;
}

.loader-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.loader-spinner {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #cf2d31;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;

  &.white {
    background: white;
  }
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

input.mat-mdc-input-element {
  margin-top: -0.0625em;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.mat-mdc-input-element {
  caret-color: #354f8d;
}

.mat-mdc-input-element,
.mat-mdc-select,
.mat-mdc-autocomplete-trigger {
  height: 50px;
  font-weight: 500;
  color: #647387 !important;
  border: 2px solid #e4e8f1 !important;
  border-radius: 6px;
}

.mat-mdc-input-element {
  font-family: var(--font-default) !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  padding: 0.375rem 0.75rem !important;
  vertical-align: initial !important;
  text-align: inherit !important;
  box-sizing: border-box !important;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.mat-mdc-autocomplete-panel {
  background-color: white !important;
}

.mat-datepicker-content-container {
  background: white !important;
  box-shadow: var(--mat-autocomplete-container-elevation-shadow) !important;
}
