@use '@angular/material' as mat;

@include mat.core();

// Definir o tema completo com a nova estrutura.
$hmax-dashboard-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$blue-palette,
      tertiary: mat.$orange-palette,
    ),
    typography: (
      brand-family: 'Roboto, sans-serif',
      plain-family: 'Arial, sans-serif',
      bold-weight: 700,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Aplicar o tema para todos os componentes.
html {
  @include mat.all-component-themes($hmax-dashboard-theme);
}
