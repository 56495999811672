/**=====================
     76. Language CSS start
==========================**/
.current_lang {
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
}
.lang {
  span.lang-txt {
    display: inline-flex;
    margin-left: 8px;
    font-weight: 500;
  }

  span {
    span {
      color: #999;
      font-weight: 400;
      margin-left: 5px;
    }
  }
}

/*more lang*/

.more_lang {
  cursor: pointer;
  .lang {
    padding: 10px 15px;
    display: flex;
    &.selected {
      box-shadow: 0 0 11px 5px rgb(226 226 226 / 50%);
    }
  }
}
/**=====================
     76. Language CSS end
==========================**/
